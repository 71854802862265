import React from 'react';
import {
  Button,
  Jumbotron } from 'reactstrap';
  import Popup from "reactjs-popup";
import * as _ from "lodash";
import './SearchForm.css';
import download from './img/download.png';
import arrow from './img/arrow.svg';
import '@fortawesome/fontawesome-free/css/all.css';

const alternatingColor = ['#f2f3f4', '#ffffff'];

const PopupExample = () => (
    <Popup trigger={<button>v 0.2.3</button>} position="bottom left">
        {close => (
        <div>
            <a href="/#" className="close" onClick={close}>
                &times;
            </a>
            <div>
                <b>0.2.3 - 20230331</b>
                <p>Removed '888' clause in account SQL query</p>
            </div>
            <div>
                <b>0.2.2 - 20200916</b>
                <p>Fixed "Reset"</p>
                <p>Fixed "Close" button on "No SPFs For Account"</p>
                <p>Added "Info" button to display UPN info for test account</p>
            </div>
            <div>
                <b>0.2.1 - 20200701</b>
                <p>Removed all accounts that are associated with 888</p>
            </div>
            <div>
                <b>0.2.0 - 20200513</b>
                <p>Re-architected MSSQL pooling connections</p>
                <p>Separated all AJAX calls into discrete methods and fixed Promises</p>
            </div>
            <div>
                <b>0.1.37 - 20200409</b>
                <p>Added notification for Sales users with no Sales IDs</p>
                <p>Added instrumentation to help with debugging.</p>
            </div>
            <div>
                <b>0.1.36 - 20200303</b>
                <p>Added notification for Sales users with no Sales IDs</p>
                <p>Added instrumentation to help with debugging.</p>
            </div>
            <div>
                <b>0.1.35 - 20200120</b>
                <p>Updated MSAL Graph SDK</p>
                <p>Added Latin America to user access lookup.</p>
            </div>
            <div>
                <b>0.1.34 - 20200120</b>
                <p>Updated the "accounts" REST API for better search results.</p>
            </div>
            <div>
                <b>0.1.33 - 20200102</b>
                <p>Creating a Sales Admin version that allows them to search a specific user.</p>
            </div>
            <div>
                <b>0.1.32 - 20200102</b>
                <p>Fixed issue where downloads failed if not connected to VPN</p>
            </div>
            <div>
                <b>0.1.31 - 20191213</b>
                <p>Removed ERPs reset from CancelAccountSearch</p>
            </div>
            <div>
                <b>0.1.30 - 20191216</b>
                <p>Set DEV specfic build for admin testing</p>
            </div>
            <div>
                <b>0.1.29 - 20191213</b>
                <p>Set test email to Kevin Cahill</p>
                <p>Set O365 login errors to more legible format</p>
                <p>Disable view for non-authorized users</p>
                <p>Set API URL to PROD</p>
            </div>
            <div>
                <b>0.1.28 - 20191122</b>
                <p>Corrected SSO workflow</p>
                <p>Fixed spelling errors</p>
                <p>Added different views based on user access</p>
            </div>
            <div>
                <b>0.1.27 - 20191121</b>
                <p>Removed user access shim</p>
                <p>Allow Test Login Account only for administrators.</p>
            </div>
            <div>
                <b>0.1.26</b>
                <p>Modified PROD database connections</p>
            </div>
            <div>
                <b>0.1.25</b>
                <p>Added selected accountid/accountname to SPF table title to show which account was being searched</p>
                <p>No longer displaying any accounts with "Account Closed" in title</p>
                <p>No longer displaying any accounts with ids between 49999 and 70000</p>
            </div>
            <div>
                <b>0.1.24</b>
                <p>Added Ggogle Analytics code stub</p>
                <p>Fixed column headers nowrap</p>
            </div>
            <div>
                <b>0.1.23</b>
                <p>Added City and State form elements to account search</p>
                <p>Removed test account from form</p>
                <p>Added tooltip to column headers for sort and search tip</p>
                <p>Inactive SPFs are deselected by default</p>
            </div>
            <div>
                <b>0.1.22</b>
                <p>When clicking SPF button, no search fields should be populated</p>
                <p>When changing the contractor dropdown, no modal shown for maximum records (400)</p>
            </div>
            <div>
                <b>0.1.21</b>
                <p>Hitting return on Account search submits</p>
                <p>Set maximum SPF records to 400</p>
                <p>Created HTTP to HTTPS redirect</p>
                <p>Add sort indicators next to sorted column</p>
            </div>
            <div>
                <b>0.1.20</b>
                <p>Add "All Contractors" to Contractor dropdown</p>
                <p>Display processing modal when filtering contractors</p>
                <p>Sort columns by clicking on header</p>
            </div>
            <div>
                <b>0.1.19</b>
                <p>Active/inactive search implemented</p>
                <p>Do not modify account number/name when searching for SPFs</p>
                <p>Add "All Contractors" to Contractor dropdown</p>
                <p>Display processing modal when filtering contractors</p>
            </div>
            <div>
                <b>0.1.18</b>
                <p>Added close button for max SPF rows</p>
                <p>Widened Title field</p>
                <p>Clear selected contractor on account search</p>
                <p>Clear SPF results on account search</p>
                <p>Show modal for empty account search</p>
                <p>Show modal for empty SPF search</p>
            </div>
            <div>
                <b>0.1.17</b>
                <p>Added modal for SPFS over 600</p>
                <p>Fixed "Reset" so that SPF table is cleared.</p>
                <p>Added modal to indicate that app is processing request</p>
                <p>Contractor sorts correctly now</p>
                <p>Case insensitive search now works</p>
            </div> 
            <div>
                <b>0.1.16</b>
                <p>Download SPF is implemented</p>
                <p>Fixed Contractor filter</p>
            </div> 
            <div>
                <b>0.1.15</b>
                <p>Sort by Title on SPF list</p>
                <p>Widen Title field </p>
                <p>Sort Contractor dropdown</p>
                <p>Added alternating row color</p>
            </div> 
            <div>
                <b>0.1.14</b>
                <p>Fixed Contractor search dropdown</p>
            </div> 
            <div>
                <b>0.1.13</b>
                <p>Correct columns being displayed</p>
            </div> 
            <div>
                <b>0.1.12</b>
                <p>Fixed user account title to "Test Login Account"</p>
                <p>Added login account error checking</p>
                <p>Added version descriptions</p>
            </div>                
        </div>
        )}
    </Popup>
);

class AccountSearchForm extends React.Component {    
    render() {
        return (
            <div>
                <form onSubmit={this.props.GetAccountsSearch}>   
                    {this.props.isAuthenticated && (this.props.isadmin || this.props.erps) &&
                        <p className="ms-font-su ms-fontColor-themePrimary column-header-style">Account Search Form</p>
                    }                
                    {this.props.isAuthenticated && this.props.isadmin &&
                        <div className="col-md-4 col-md-offset-12 ms-TextField ui-widget text-form-element">
                            <label className="ms-Label">
                                <div style={{width: '160px'}}>Test Login Account <i className="fas fa-info-circle" style={{width: '32px',  cursor: 'pointer'}} onClick={this.props.GetUserInfo}></i></div>
                            </label>
                            <input id="TextBoxTestEmailAccount" value={this.props.testemail} onChange={this.props.onChangeTestEmail} />
                        </div>
                    }
                    {this.props.isAuthenticated && (this.props.isadmin || this.props.erps) &&
                        <div>
                            <div className="col-md-4 col-md-offset-12 ms-TextField ui-widget text-form-element">
                                <label className="ms-Label unset-style">Account Number</label>
                                <input id="TextBoxAccountNumber" value={this.props.searchaccountid} onChange={this.props.handleAccountIDChange} />
                            </div>
                            <div className="col-md-4 col-md-offset-12 ms-TextField ui-widget text-form-element">
                                <label className="ms-Label unset-style">Account Name</label>
                                <input id="TextBoxAccountName" value={this.props.accountname} onChange={this.props.handleAccountNameChange} />
                            </div>
                            <div className="col-md-4 col-md-offset-12 ms-TextField ui-widget text-form-element">
                                <label className="ms-Label unset-style">City</label>
                                <input id="TextBoxAccountCity" value={this.props.accountcity} onChange={this.props.handleAccountCityChange} />
                            </div>
                            <div className="col-md-4 ms-TextField ui-widget text-form-element">
                                <label className="ms-Label unset-style">State</label>
                                <select value={this.props.selectedState} 
                                    onChange={this.props.handleSelectedState} className="selectState">
                                    <option key="0" value=""> -- Select State -- </option>
                                    {this.props.states.map((item, index) => <option key={index} value={item.value}>{item.text}</option>)}
                                </select>
                            </div>
                            <Button id="buttonResetForm" type="button" className="btn btn-default btn-default btn-reset" onClick={this.props.CancelAccountSearch}>
                                <span className="glyphicon glyphicon-repeat"></span>Reset
                            </Button>
                            <Button color="primary" id="buttonAccountSearch" type="submit" className="btn btn-primary btn-primary">
                                <span className="glyphicon glyphicon-search"></span>Search
                            </Button>
                        </div>
                    }
                </form>
            </div>            
        );
    }
}

function WelcomeContent(props) {
    if (!(props.isAuthenticated)) {
        return (
            <Jumbotron>
                <h1>SPF Web App</h1>
                <h4>To start working with this application, please sign in with your login credentials.</h4>
            </Jumbotron>
        );
    }
    else {
        if ((props.isAuthenticated) && (!(props.isadmin)) && (props.erpsfetched) && (props.erps)) {
            return (
                <div className="row">
                    <div className="col-md-12 header-style">
                        <span className="ms-font-su ms-fontColor-themePrimary page-title-header">Sales SPF Web App</span>
                        <p>
                            This application allows you to search for all accounts for which you have access.  To get started, start typing an account name or number in the fields below.
                        </p>
                    </div>
                </div>
            );
        }
        else if ((props.isAuthenticated) && (!(props.isadmin)) && (props.erpsfetched) && (!(props.erps))) {
            return (
                <Jumbotron>
                    <h1>SPF Web App</h1>
                    <h4>There are no Sales IDs associated with this account, please contact Help Desk for help with this issue.</h4>
                </Jumbotron>
            );
        }
				else if ((props.isAuthenticated) && (props.isadmin)) {
					return (
						<div className="row">
							<div className="col-md-12 header-style">
									<span className="ms-font-su ms-fontColor-themePrimary page-title-header">Sales SPF Web App</span>
									<p>
											This application allows you to search for all accounts for which you have access.  To get started, start typing an account name or number in the fields below.
									</p>
							</div>
					</div>
					);
				}
				else {
            return (
                <div className="row">&nbsp;</div>
            );
        }
    }
}

export default class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isadmin: false,
            adminlevel: null,
            sids: [],
            accounts: [],
            erps: null,
            erpsfetched: false,
            spfs: [],
            contractors: [],
            selectedContractor: "",
            selectedState: "",
            token: "",
            email: "",
            upn: "",
            testuserupn: "",
            testemail: "",
            searchaccountid: "",
            spfaccountid: "",
            spfaccountname: "",
            accountnumber: "",
            accountname: "",
            accountcity: "",
            accountstate: "",
            contractorname: "",
            SPFTableTitle: "",
            showNoAccessForm: true,
            showWelcomeForm: false,
            showSearchForm: false,
            showAccountsForm: false,
            showSPFsForm: false,
            showAdminTestLoginAccount: false,
            showVersion: false,
            showForm: false,
            open: false,
            processoropen: false,
            startdownload: false,
            noresultsopen: false,
            nospfsopen: false,
            getuserinfoopen: false,
            getuserinfoiconshow: false,
            spfstatusactive: true,
            spfstatusinactive: false,
            spfsortbyfield: obj => obj.title,
            spfsortdirection: _.sortBy,
            maxspfs: 400,
            spfsortbytitle: true,
            spfsortbyproject: false,
            spfsortbytype: false,
            spfsortbycomment: false,
            spfsortbyshipto: false,
            spfsortbyterritory: false
        };

        this.GetIsAdmin = this.GetIsAdmin.bind(this);
        this.StartUserAccess = this.StartUserAccess.bind(this);
        this.GetUserERPS = this.GetUserERPS.bind(this);
        this.GetUserInfo = this.GetUserInfo.bind(this);
        this.GetDivMgrERPS = this.GetDivMgrERPS.bind(this);
        this.GetRegMgrERPS = this.GetRegMgrERPS.bind(this);
        this.GetLineSalesERPS = this.GetLineSalesERPS.bind(this);
        this.GetWebDocsToken = this.GetWebDocsToken.bind(this);
        this.GetAccountsSearch = this.GetAccountsSearch.bind(this);
        this.GetSPFSearch = this.GetSPFSearch.bind(this);
        this.handleTestEmailChange = this.handleTestEmailChange.bind(this);
        this.handleAccountIDChange = this.handleAccountIDChange.bind(this);
        this.handleAccountNameChange = this.handleAccountNameChange.bind(this);
        this.handleAccountCityChange = this.handleAccountCityChange.bind(this);
        this.handleAccountStateChange = this.handleAccountStateChange.bind(this);
        this.handleContractorNameChange = this.handleContractorNameChange.bind(this);
        this.handleSelectedContractor = this.handleSelectedContractor.bind(this);
        this.handleSelectedState = this.handleSelectedState.bind(this);
        this.CancelAccountSearch = this.CancelAccountSearch.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeProcessorModal = this.closeProcessorModal.bind(this);
        this.openProcessorModal = this.openProcessorModal.bind(this);
        this.closeDownloadModal = this.closeDownloadModal.bind(this);
        this.openDownloadModal = this.openDownloadModal.bind(this);
        this.closeNoResultsModal = this.closeNoResultsModal.bind(this);
        this.openNoResultsModal = this.openNoResultsModal.bind(this);
        this.closeGetUserInfoModal = this.closeGetUserInfoModal.bind(this);
        this.openGetUserInfoModal = this.openGetUserInfoModal.bind(this);
        this.closeNoActiveOrInactiveModal = this.closeNoActiveOrInactiveModal.bind(this);
        this.openNoActiveOrInactiveModal = this.openNoActiveOrInactiveModal.bind(this);
        this.closeNoSPFsModal = this.closeNoSPFsModal.bind(this);
        this.openNoSPFsModal = this.openNoSPFsModal.bind(this);
        this.handleSPFStatusActiveChange = this.handleSPFStatusActiveChange.bind(this);
        this.handleSPFStatusInactiveChange = this.handleSPFStatusInactiveChange.bind(this);
        this.SortSPFsByTitle = this.SortSPFsByTitle.bind(this);
        this.SortSPFsByProject = this.SortSPFsByProject.bind(this);
        this.SortSPFsByType = this.SortSPFsByType.bind(this);
        this.SortSPFsByComment = this.SortSPFsByComment.bind(this);
        this.SortSPFsByShipTo = this.SortSPFsByShipTo.bind(this);
        this.SortSPFsByTerritory = this.SortSPFsByTerritory.bind(this);
        this.DownloadSPFDocument = this.DownloadSPFDocument.bind(this);

        this.states = [
            {value: "AL", text: "Alabama"},
            {value: "AK", text: "Alaska"},
            {value: "AZ", text: "Arizona"},
            {value: "AR", text: "Arkansas"},
            {value: "CA", text: "California"},
            {value: "CO", text: "Colorado"},
            {value: "CT", text: "Connecticut"},
            {value: "DE", text: "Delaware"},
            {value: "DC", text: "District Of Columbia"},
            {value: "FL", text: "Florida"},
            {value: "GA", text: "Georgia"},
            {value: "HI", text: "Hawaii"},
            {value: "ID", text: "Idaho"},
            {value: "IL", text: "Illinois"},
            {value: "IN", text: "Indiana"},
            {value: "IA", text: "Iowa"},
            {value: "KS", text: "Kansas"},
            {value: "KY", text: "Kentucky"},
            {value: "LA", text: "Louisiana"},
            {value: "ME", text: "Maine"},
            {value: "MD", text: "Maryland"},
            {value: "MA", text: "Massachusetts"},
            {value: "MI", text: "Michigan"},
            {value: "MN", text: "Minnesota"},
            {value: "MS", text: "Mississippi"},
            {value: "MO", text: "Missouri"},
            {value: "MT", text: "Montana"},
            {value: "NE", text: "Nebraska"},
            {value: "NV", text: "Nevada"},
            {value: "NH", text: "New Hampshire"},
            {value: "NJ", text: "New Jersey"},
            {value: "NM", text: "New Mexico"},
            {value: "NY", text: "New York"},
            {value: "NC", text: "North Carolina"},
            {value: "ND", text: "North Dakota"},
            {value: "OH", text: "Ohio"},
            {value: "OK", text: "Oklahoma"},
            {value: "OR", text: "Oregon"},
            {value: "PA", text: "Pennsylvania"},
            {value: "RI", text: "Rhode Island"},
            {value: "SC", text: "South Carolina"},
            {value: "SD", text: "South Dakota"},
            {value: "TN", text: "Tennessee"},
            {value: "TX", text: "Texas"},
            {value: "UT", text: "Utah"},
            {value: "VT", text: "Vermont"},
            {value: "VA", text: "Virginia"},
            {value: "WA", text: "Washington"},
            {value: "WV", text: "West Virginia"},
            {value: "WI", text: "Wisconsin"},
            {value: "WY", text: "Wyoming"}
        ];

        // this.adminList = [
        //     /*===== UNCOMMENT BELOW FOR SALESADMIN =====*/
        //     "peter.briggeman@victaulic.com",
        //     "phil.marchello@victaulic.com",
        //     "john.maring@victaulic.com",            
        //     "jason.heald@victaulic.com",
        //     "scott.haslam@victaulic.com",
        //     "renee.pecuch@victaulic.com",
        //     "michele.acciarito@victaulic.com",
        //     "fred.haas@victaulic.com",
        //     "it_jm@victaulic.com"
        // ];

        // this.superAdminList = [
        //     /*===== UNCOMMENT BELOW FOR SALESADMIN =====*/
        //     "peter.briggeman@victaulic.com",
        //     "phil.marchello@victaulic.com",
        //     "john.maring@victaulic.com",            
        //     "it_jm@victaulic.com"
        //   ];
    }

    componentDidUpdate() {
        if ((this.props.user) && (this.state.email === "") && (this.props.user.email) && (this.props.user.upn)) {
            this.setState({ email : this.props.user.email, upn : this.props.user.upn.toLowerCase() }, function () {          
                this.StartUserAccess();
            });
        }
    }

    async StartUserAccess() {        
        if (this.state.email) {
            await this.GetIsAdmin();
        }
    }

    async GetIsAdmin() {
        try {
            const res = await fetch(process.env.REACT_APP_HOST + '/admin/isadmin',
            {
                method: 'POST',
                body: JSON.stringify({
                    email: this.state.email
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            
            const json = await res.json();
						if (json.length > 0) {
							// set admin level
							this.setState({ isadmin : true, adminlevel : json[0].level, showVersion : true });
						}
						else {
							await this.GetUserERPS();
						}            
        } catch (err) {
            // ... error checks
            console.log("Fetch error: " + err);
        }
    }

    async GetUserERPS() {
			const divMgr = await this.GetDivMgrERPS();			
			if (!(divMgr)) {
				const regMgr = await this.GetRegMgrERPS();			
				if (!(regMgr)) {
					await this.GetLineSalesERPS();
				}
			}
		}
		
		async GetDivMgrERPS() {
			try {
				const res = await fetch(process.env.REACT_APP_HOST + '/access/getdivmgrerps',
				{
						method: 'POST',
						body: JSON.stringify({
								upn: this.state.upn
						}),
						headers: {
								"Content-type": "application/json; charset=UTF-8"
						}
				});

				this.setState({ erpsfetched : true});

				const json = await res.json();
				if (json.length > 0) {
					// user is division mgr
					this.setState({ erps : json });
					return true;
				}
				else {
					return false;
				} 
			} catch (err) {
					// ... error checks
					console.log("Fetch error: " + err);
			}
		}

		async GetRegMgrERPS() {
			try {
				const res = await fetch(process.env.REACT_APP_HOST + '/access/getregmgrerps',
				{
						method: 'POST',
						body: JSON.stringify({
								upn: this.state.upn
						}),
						headers: {
								"Content-type": "application/json; charset=UTF-8"
						}
				});

				const json = await res.json();

				this.setState({ erpsfetched : true});

				if (json.length > 0) {
					// user is division mgr
					this.setState({ erps : json });
					return true;;
				}
				else {
					return false;
				} 
			} catch (err) {
					// ... error checks
					console.log("Fetch error: " + err);
			}
		}

		async GetLineSalesERPS() {
            // console.log("UPN: " + this.state.upn);
            try {
				const res = await fetch(process.env.REACT_APP_HOST + '/access/getlinesaleserps',
				{
                    method: 'POST',
                    body: JSON.stringify({
                            upn: this.state.upn
                    }),
                    headers: {
                            "Content-type": "application/json; charset=UTF-8"
                    }
				});

                const json = await res.json();
                
                console.log("LINE SALES: " + JSON.stringify(json));

				this.setState({ erpsfetched : true});

				if (json.length > 0) {
					// user is division mgr
					this.setState({ erps : json });
					return true;
				}
				else {
					return false;
				}
			} catch (err) {
					// ... error checks
					console.log("Fetch error: " + err);
			}
		}

        async GetUserInfo() {
            this.setState({ getuserinfoopen: true });
        }
    
        async GetWebDocsToken() {
        // console.log("GetWebDocsToken" + this.state.email);
        try {
            // get webdocs token
            const resToken = await fetch(process.env.REACT_APP_HOST + '/spfs',
            {
                method: 'GET',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

            const jsonToken = await resToken.json();
            // console.log(jsonToken.access_token);
            this.setState({ token: jsonToken.access_token });
        } catch (err) {
            // ... error checks
            console.log("Fetch error: " + err);
        }
    }

    async GetAccountsSearch(e) {
			e.preventDefault();
			this.setState({
				accounts: [],
				contractors: [],
				erps: [],
				spfs: [],
				processoropen: false,
                showSPFsForm: false,
                getuserinfoiconshow: false,
				selectedContractor: "" 
			});

			if (this.state.isadmin) {
				if (!(this.state.testemail.length > 0)) {
					alert('You must provide a valid test log account!');
					this.setState({
						processoropen: false
					});
					return;
				}
				else {
					await this.GetUserERPS();
				}

				if (this.state.erps.length === 0) {
					alert('This account does not have any SalesIds associated with it!');
					this.setState({
						processoropen: false
					});
					return;
				}
			}

			/****************************************************
			*		GET SalesVerticalIDs
			*****************************************************/

			this.setState({
				processoropen: true
			});

			console.log("GETVERTICALIDS");

			const res = await fetch(process.env.REACT_APP_HOST + '/accounts/getsalesverticalids',
			{
					method: 'POST',
					body: JSON.stringify({
							erps: this.state.erps.join(',')
					}),
					headers: {
							"Content-type": "application/json; charset=UTF-8"
					}
			});

			const json = await res.json();			
			this.setState({
					sids: json
			});

			if (json.length === 0) {
					this.setState({
							noresultsopen: true
					});
			}

			/****************************************************
			*		GET SalesVerticalIDs
			*****************************************************/
			console.log("GETUSERACCOUNTS");
			const resa = await fetch(process.env.REACT_APP_HOST + '/accounts/getuseraccounts',
			{
					method: 'POST',
					body: JSON.stringify({
							accountnumber: this.state.searchaccountid,
							accountname: this.state.accountname,
							accountcity: this.state.accountcity,
							accountstate: this.state.selectedState,
							sids: this.state.sids.join(',')
					}),
					headers: {
							"Content-type": "application/json; charset=UTF-8"
					}
			});

			const jsona = await resa.json();

			this.setState({
					accounts: jsona,
                    showAccountsForm: true,
                    getuserinfoiconshow: true,
					processoropen: false
			});

			if (jsona.length === 0) {
					this.setState({
							noresultsopen: true
					});
			}
			else {
				await this.GetWebDocsToken();
			}
    }

    async GetSPFSearch(e) {
        var accountnumber = e.target.getAttribute('accountnumber');
        var accountname = e.target.getAttribute('accountname');
        var token = e.target.getAttribute('token');
        var spfstatusactive = this.state.spfstatusactive;
        var spfstatusinactive = this.state.spfstatusinactive;
        var active = "";
        
        this.setState({
            spfs: [],
            contractors: [],
            showSPFsForm: false,
            spfaccountid: accountnumber,
            spfaccountname: accountname,
            token: token,
            processoropen: true,
            selectedContractor: "",
            SPFTableTitle: ""
        });
        
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        var today = [year, month, day].join('-');
        // console.log(today);


        console.log("STATUS ACTIVE:" + spfstatusactive);
        console.log("STATUS INACTIVE:" + spfstatusinactive);

        if ((!(spfstatusactive)) && !(spfstatusinactive)) {
            // just inactive
            // console.log("JUST INACTIVE");
            // active = "[19190101 TO " + today + "]";
            // console.log("NO ACTIVE OR INACTIVE SPFS SELECTED!");
            this.openNoActiveOrInactiveModal();
            this.setState({
                processoropen: false,
                spfs: []
            });
            return;
        }
        else if (spfstatusactive && spfstatusinactive) {
            active = "[19190101 TO 99990101]";
        }
        else {
            if (spfstatusinactive) {
                // just inactive
                // console.log("JUST INACTIVE");
                active = "[19190101 TO " + today + "]";
            }
            else if (spfstatusactive) {
                // just active
                // console.log("JUST ACTIVE");
                active = "[" + today + " TO 99990101]";
            }
        }
 
        var tmp = [];

        for (let i = 0; i < 6; i++) {
            const res = await fetch(process.env.REACT_APP_HOST + '/spfs',
            {
                method: 'POST',
                body: JSON.stringify({
                    accountnumber: accountnumber,
                    // accountname: accountname,
                    token: token,
                    contractorname: this.state.contractorname,
                    active: active,
                    page: i
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

            const json = await res.json();

            if (json !== "[]") {
                this.setState({
                    spfs: this.state.spfs.concat(json),
                    showSPFsForm: true
                });
                
                json.forEach( function (item) {
                    var contractor = item.fields[11].value;
                    if (contractor !== ""){
                        if(tmp.indexOf(contractor) === -1){
                            tmp.push(contractor);
                        }
                    }
                });
            }
            else {
                break;       
            } 
        }

        if (this.state.spfs.length >= this.state.maxspfs) {
            this.openModal();
        }

        // console.log("SPFS: " + this.state.spfs.length);

        if (this.state.spfs.length === 0) {
            this.setState({
                nospfsopen: true
            });
        }

        this.setState({
            contractors: tmp,
            SPFTableTitle: this.state.accountnumber + " - " + this.state.accountname,
            processoropen: false
        });
    }

    async DownloadSPFDocument(e) {
        // console.log("DOCID: " + e.target.getAttribute('docid'));
        this.setState({
            startdownload: true
        });
        const spfDocID = e.target.getAttribute('docid');
        const spfDocExt = e.target.getAttribute('docext');
        const spfDocTitle = e.target.getAttribute('doctitle');
        try {
            const response = await fetch(process.env.REACT_APP_HOST + "/download/?docid=" + spfDocID + "&docext=" + spfDocExt, {
                //   method: 'GET', // or 'PUT'
                method: 'GET' // or 'PUT'
                //   headers: {
                //     'Authorization': 'Bearer tGuAq3Z3lDt-yu00LGExv0HsOk9W8yFbwS38krx3qzyt_YlEWRbzUn7n-u5gdBmNdua8lmnQ2a_0YvAHM3AhNCeVOWaDkCFK7J8QVLw04wJe4jQ1SH6me6Zdo3QBYC7mERSQqEgN0rtMZA-7YSoJ8gVaVr07SXuoKEtC_DcPwyMaNvWZeTlj2dr_QvaAyr2mIS7zYdKaMfm4gNW3D27JWzU5D_R6ZZilUENWX5NxI6WPYrxvo-_2a0OlWVKAdhnSHoboeuqzaiJRDNUPlzAh5644L8G3w61efhjGmL5qqKq5O6OfQAh_goHSpHngtegAIgw5KXgYXGvuZ6CRCeQWIfAT9VV7QFlMed90_e6VE5D_c5ejGc6IgZlT8F_KPlOyLpVjQnOFh-d969TjkPM13acfJDeCOasvthlikIJfsKSj4wTSVUITPx4t2lZ5JEra',
                //     'Accept' : 'application/json'
                //   }
            });

            const json = await response.json();

            this.setState({
                startdownload: false
            });

            if (json.status === "success") {
                console.log("DOWNLOAD SERVER DOCUMENT: " + json.status);

                try {
                    const response = await fetch(process.env.REACT_APP_HOST + "/download/dlexcel?docid=" + spfDocID + "&docext=" + spfDocExt, {
                    //   method: 'GET', // or 'PUT'
                      method: 'GET' // or 'PUT'
                    //   headers: {
                    //     'Authorization': 'Bearer tGuAq3Z3lDt-yu00LGExv0HsOk9W8yFbwS38krx3qzyt_YlEWRbzUn7n-u5gdBmNdua8lmnQ2a_0YvAHM3AhNCeVOWaDkCFK7J8QVLw04wJe4jQ1SH6me6Zdo3QBYC7mERSQqEgN0rtMZA-7YSoJ8gVaVr07SXuoKEtC_DcPwyMaNvWZeTlj2dr_QvaAyr2mIS7zYdKaMfm4gNW3D27JWzU5D_R6ZZilUENWX5NxI6WPYrxvo-_2a0OlWVKAdhnSHoboeuqzaiJRDNUPlzAh5644L8G3w61efhjGmL5qqKq5O6OfQAh_goHSpHngtegAIgw5KXgYXGvuZ6CRCeQWIfAT9VV7QFlMed90_e6VE5D_c5ejGc6IgZlT8F_KPlOyLpVjQnOFh-d969TjkPM13acfJDeCOasvthlikIJfsKSj4wTSVUITPx4t2lZ5JEra',
                    //     'Accept' : 'application/json'
                    //   }
                    });
                    const blob = await response.blob();
                    // console.log('Success:', blob);
                    // return blob;
                    // this.showFile(blob);

                    var newBlob = new Blob([blob], {type: "application/vnd.ms-excel"});

                    const data = window.URL.createObjectURL(newBlob);
                    var link = document.createElement('a');
                    link.href = data;
                    link.download=spfDocTitle + spfDocExt;
                    link.click();
                    setTimeout(function(){
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data);
                    }, 100);

                    // delete the file on the server
                    // fetch(process.env.REACT_APP_HOST + "/download/delexcel?docid=" + spfDocID + "&docext=" + spfDocExt, {
                    //     method: 'GET' // or 'PUT'
                    // });

                    this.setState({
                        startdownload: false
                    });
                  } catch (error) {
                    console.error('Error:', error);
                    this.setState({
                        startdownload: false
                    });
                  }
            }
        } catch (error) {
            console.error('DOWNLOAD SERVER DOCUMENT ERROR:', error);
            this.setState({
                startdownload: false
            });
        }
        this.setState({
            startdownload: false
        });
    }

    handleTestEmailChange(e) {
        this.setState({ testemail : e.target.value, upn : e.target.value});
        // this.setState({ testemail : e.target.value }, function () {
        //     console.log(this.state.testemail);
        // });
    }

    handleAccountIDChange(e) {
        this.setState({ searchaccountid : e.target.value});
    }

    handleAccountNameChange(e) {
        this.setState({ accountname : e.target.value});
    }

    handleAccountCityChange(e) {
        this.setState({ accountcity : e.target.value});
    }

    handleAccountStateChange(e) {
        this.setState({ accountstate : e.target.value});
    }

    handleContractorNameChange(e) {
        this.setState({ contractorname : e.target.value});
    }

    async handleSelectedContractor(e) {
        var selectedContractor = e.target.value;
        var spfstatusactive = this.state.spfstatusactive;
        var spfstatusinactive = this.state.spfstatusinactive;
        var active = "";

        this.setState({
            selectedContractor : selectedContractor,
            SPFTableTitle: "",
            processoropen: true,
            spfs: []
        });

        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        var today = [year, month, day].join('-');

        if ((!(spfstatusactive)) && !(spfstatusinactive)) {
            // just inactive
            // console.log("JUST INACTIVE");
            // active = "[19190101 TO " + today + "]";
            // console.log("NO ACTIVE OR INACTIVE SPFS SELECTED!");
            this.openNoActiveOrInactiveModal();
            this.setState({
                processoropen: false
            });
            return;
        }
        else if (spfstatusactive && spfstatusinactive) {
            active = "[19190101 TO 99990101]";
        }
        else {
            if (spfstatusinactive) {
                // just inactive
                // console.log("JUST INACTIVE");
                active = "[19190101 TO " + today + "]";
            }
            else if (spfstatusactive) {
                // just active
                // console.log("JUST ACTIVE");
                active = "[" + today + " TO 99990101]";
            }
        }

        // console.log("ACCOUNTID: " + this.state.accountid);

        for (let i = 0; i < 6; i++) {
            const res = await fetch(process.env.REACT_APP_HOST + '/spfs',
            {
                method: 'POST',
                body: JSON.stringify({
                    accountnumber: this.state.spfaccountid,
                    token: this.state.token,
                    contractorname: selectedContractor,
                    active: active,
                    page: i
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

            const json = await res.json();

            if (json !== "[]") {
                this.setState({
                    spfs: this.state.spfs.concat(json),
                    showSPFsForm: true
                });  
            }
            else {
                break;       
            } 

            if (this.state.spfs.length >= this.state.maxspfs) {
                this.openModal();
            }
        }

        this.setState({
            processoropen: false,
            SPFTableTitle: this.state.accountnumber + " - " + this.state.accountname
        });
    }

    handleSelectedState(e) {
        this.setState({
            selectedState : e.target.value
        });
    }

    CancelAccountSearch() {
        this.setState({
            accounts: [],
            contractors: [],
            testemail: "",
            spfs: [],
            searchaccountid : "",
            spfaccountid : "",
            spfaccountname : "",
            accountname : "",
            accountcity : "",
            accountstate : "",
            selectedState : "",
            contractorname : "",
            showSearchForm: true,
            showAccountsForm: false,
            showSPFsForm: false,
            showForm: false
        });
    }

    closeModal() {
        this.setState({ open: false });
    }

    openModal() {
        this.setState({ open: true });
    }

    closeMaxRowsModal() {
        this.setState({ maxrowsopen: false });
    }

    openMaxRowsModal() {
        this.setState({ maxrowsopen: true });
    }

    closeProcessorModal() {
        this.setState({ processoropen: false });
    }

    openProcessorModal() {
        this.setState({ processoropen: true });
    }

    closeDownloadModal() {
        this.setState({ startdownload: false });
    }

    openDownloadModal() {
        this.setState({ startdownload: true });
    }

    closeNoResultsModal() {
        this.setState({ noresultsopen: false });
    }

    openNoResultsModal() {
        this.setState({ noresultsopen: true });
    }

    closeNoSPFsModal() {
        this.setState({ nospfsopen: false });
    }

    openNoSPFsModal() {
        this.setState({ nospfsopen: true });
    }

    closeGetUserInfoModal() {
        this.setState({ getuserinfoopen: false });
    }

    openGetUserInfoModal() {
        this.setState({ getuserinfoopen: true });
    }

    closeNoActiveOrInactiveModal() {
        this.setState({ noactiveorinactiveopen: false });
    }

    openNoActiveOrInactiveModal() {
        this.setState({ noactiveorinactiveopen: true });
    }

    handleSPFStatusActiveChange(e) {
        this.setState({
            spfstatusactive : (!(this.state.spfstatusactive)),
            spfs: [],
            showSPFsForm: false
        });
    }

    handleSPFStatusInactiveChange(e) {
        this.setState({
            spfstatusinactive : (!(this.state.spfstatusinactive)),
            spfs: [],
            showSPFsForm: false
        });
    }

    SortSPFsByTitle() {
        this.setState({
            spfsortbyfield: obj => obj.title,
            spfsortbytitle: true,
            spfsortbyproject: false,
            spfsortbytype: false,
            spfsortbycomment: false,
            spfsortbyshipto: false,
            spfsortbyterritory: false
        });
    }

    SortSPFsByProject() {
        this.setState({
            spfsortbyfield: obj => obj.fields[0].value,
            spfsortbytitle: false,
            spfsortbyproject: true,
            spfsortbytype: false,
            spfsortbycomment: false,
            spfsortbyshipto: false,
            spfsortbyterritory: false
        });
    }

    SortSPFsByType() {
        this.setState({
            spfsortbyfield: obj => obj.fields[1].value,
            spfsortbytitle: false,
            spfsortbyproject: false,
            spfsortbytype: true,
            spfsortbycomment: false,
            spfsortbyshipto: false,
            spfsortbyterritory: false
        });
    }

    SortSPFsByComment() {
        this.setState({
            spfsortbyfield: obj => obj.fields[2].value,
            spfsortbytitle: false,
            spfsortbyproject: false,
            spfsortbytype: false,
            spfsortbycomment: true,
            spfsortbyshipto: false,
            spfsortbyterritory: false
        });
    }

    SortSPFsByShipTo() {
        this.setState({
            spfsortbyfield: obj => obj.fields[12].value,
            spfsortbytitle: false,
            spfsortbyproject: false,
            spfsortbytype: false,
            spfsortbycomment: false,
            spfsortbyshipto: true,
            spfsortbyterritory: false
        });
    }

    SortSPFsByTerritory() {
        this.setState({
            spfsortbyfield: obj => obj.fields[9].value,
            spfsortbytitle: false,
            spfsortbyproject: false,
            spfsortbytype: false,
            spfsortbycomment: false,
            spfsortbyshipto: false,
            spfsortbyterritory: true
        });
    }

    render() {
        const {
            showAccountsForm, 
            showSPFsForm,
            showVersion,
            spfsortbytitle,
            spfsortbyproject,
            spfsortbytype,
            spfsortbycomment,
            spfsortbyshipto,
            spfsortbyterritory,
            getuserinfoiconshow
        } = this.state;

        const showAccountsFormStyle = showAccountsForm ? {} : { visibility: 'hidden' };
        const showSPFsFormStyle = showSPFsForm ? {} : { visibility: 'hidden' };
        const showTitleArrowStyle = spfsortbytitle ? {} : { visibility: 'hidden' };
        const showProjectArrowStyle = spfsortbyproject ? {} : { visibility: 'hidden' };
        const showTypeArrowStyle = spfsortbytype ? {} : { visibility: 'hidden' };
        const showCommentArrowStyle = spfsortbycomment ? {} : { visibility: 'hidden' };
        const showShipToArrowStyle = spfsortbyshipto ? {} : { visibility: 'hidden' };
        const showTerritoryArrowStyle = spfsortbyterritory ? {} : { visibility: 'hidden' };
        const showVersionStyle = showVersion ? {} : { visibility: 'hidden' };
        const showGetUserInfoIconStyle = getuserinfoiconshow ? {} : { visibility: 'hidden' };
        
        return (
            <div>
                <WelcomeContent
                    isAuthenticated={this.props.isAuthenticated}
                    authButtonMethod={this.props.authButtonMethod}
                    erps={this.state.erps}
                    erpsfetched={this.state.erpsfetched}
                    isadmin={this.state.isadmin} />

                <div className="row">
                    <div className="col-md-4 cell-center-style">
                        <div className="cell-center-style search-account-form">
                            <AccountSearchForm
                                isAuthenticated={this.props.isAuthenticated}
                                user={this.props.user}
                                onChangeTestEmail={this.handleTestEmailChange}
                                testemail={this.state.testemail}
                                erps={this.state.erps}
                                isadmin={this.state.isadmin}
                                searchaccountid={this.state.searchaccountid}
                                handleAccountIDChange={this.handleAccountIDChange}
                                accountname={this.state.accountname}
                                handleAccountNameChange={this.handleAccountNameChange}
                                accountcity={this.state.accountcity}
                                handleAccountCityChange={this.handleAccountCityChange}
                                states={this.states}
                                selectedState={this.state.selectedState}
                                handleSelectedState={this.handleSelectedState}
                                GetAccountsSearch={this.GetAccountsSearch}
                                CancelAccountSearch={this.CancelAccountSearch}
                                GetUserInfo={this.GetUserInfo}
                                GetUserInfoIconShow={showGetUserInfoIconStyle} />
                        </div>
                    </div>
                    <div className="col-md-8 header-style">
                        <p style={showAccountsFormStyle} className="ms-font-su ms-fontColor-themePrimary column-header-style">Accounts</p>
                        <div style={showAccountsFormStyle} className="cell-center-style-status">
                            <div className="text-form-element-status">
                                <input type="checkbox" name="status" value="active" defaultChecked={this.state.spfstatusactive} onChange={this.handleSPFStatusActiveChange} /> Active SPFs<br />
                                <input type="checkbox" name="status" value="inactive" defaultChecked={this.state.spfstatusinactive} onChange={this.handleSPFStatusInactiveChange} /> Inactive SPFs<br />
                            </div>
                        </div>
                        <div style={showAccountsFormStyle} className="scrollableAccountList">
                            <table id="TableAccountResults">
                                <tbody>
                                    {this.state.accounts.map(account => (
                                        <tr key={account.acctnumber}>
                                            <td className="left-align-data-cell">{account.acctnumber}</td>
                                            <td className="left-align-data-cell">{account.acctname} - {account.city}, {account.state}</td>
                                            <td className="right-align-data-cell"><button type='button' className='btn btn-primary' token={this.state.token} accountnumber={account.acctnumber} accountname={account.acctname} onClick={this.GetSPFSearch}><span className='glyphicon glyphicon-search'></span> SPFs</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div style={showSPFsFormStyle} className="col-md-12 SPFList cell-center-style">
                        <p className="ms-font-su ms-fontColor-themePrimary column-header-style">SPFs ({this.state.spfaccountid} - {this.state.spfaccountname})</p>
                        <table id="TableSPFResults">
                            <thead>
                                <tr>
                                    <th className="ms-font-su ms-fontColor-themePrimary table-header-style table-header-nowrap" onClick={this.SortSPFsByTitle} title="Click to sort this column, use Ctrl + F to search">Title<img style={showTitleArrowStyle} className="arrowUp" src={arrow} width='16' height='16' alt="arrow"/></th>
                                    <th className="ms-font-su ms-fontColor-themePrimary table-header-style table-header-nowrap" onClick={this.SortSPFsByProject} title="Click to sort this column, use Ctrl + F to search">Project<img style={showProjectArrowStyle} className="arrowUp" src={arrow} width='16' height='16' alt="arrow"/></th>
                                    <th className="ms-font-su ms-fontColor-themePrimary table-header-style table-header-nowrap" onClick={this.SortSPFsByType} title="Click to sort this column, use Ctrl + F to search">Type<img style={showTypeArrowStyle} className="arrowUp" src={arrow} width='16' height='16' alt="arrow"/></th>
                                    <th className="ms-font-su ms-fontColor-themePrimary table-header-style table-header-nowrap" onClick={this.SortSPFsByComment} title="Click to sort this column, use Ctrl + F to search">Comment<img style={showCommentArrowStyle} className="arrowUp" src={arrow} width='16' height='16' alt="arrow"/></th>
                                    <th className="ms-font-su ms-fontColor-themePrimary table-header-style table-header-nowrap" onClick={this.SortSPFsByShipTo} title="Click to sort this column, use Ctrl + F to search">Ship To<img style={showShipToArrowStyle} className="arrowUp" src={arrow} width='16' height='16' alt="arrow"/></th>
                                    <th className="ms-font-su ms-fontColor-themePrimary table-header-style table-header-nowrap" onClick={this.SortSPFsByTerritory} title="Click to sort this column, use Ctrl + F to search">Territory<img style={showTerritoryArrowStyle} className="arrowUp" src={arrow} width='16' height='16' alt="arrow"/></th>
                                    <th className="table-header-nowrap">Contractor</th>
                                    <th className="table-header-nowrap">Active</th>
                                    <th className="table-header-nowrap">Download</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        {/* <input id="TextBoxContrctorName" value={this.state.contractorname} onChange={this.handleContractorNameChange} /> */}
                                        {/* <select value={this.state.selectedContractor} 
                                                onChange={(e) => this.setState({selectedContractor: e.target.value})}> */}
                                        <select value={this.state.selectedContractor} 
                                            onChange={this.handleSelectedContractor} className="selectContractor">
                                            <option key="0" value="">All Contractors</option>
                                            {/* {_.sortBy(this.state.contractors, 0).map((item, index) => <option key={index} value={item}>{item}</option>)} */}
                                            {this.state.contractors.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                        </select>
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.spfsortdirection(this.state.spfs, this.state.spfsortbyfield).map(( spf, index ) => (
                                    <tr key={spf.id} bgcolor={alternatingColor[index % alternatingColor.length]}>
                                        <td width="37%" className="left-align-data-cell" title={spf.title}>{spf.title}</td>
                                        <td width="13%" className="left-align-data-cell">{spf.fields[0].value}</td>
                                        <td width="12%" className="left-align-data-cell">{spf.fields[1].value}</td>
                                        <td width="10%" className="left-align-data-cell">{spf.fields[2].value}</td>
                                        <td width="11%" className="left-align-data-cell">{spf.fields[12].value}</td>
                                        <td width="10%" className="center-align-data-cell">{spf.fields[9].value}</td>
                                        <td width="5%" className="center-align-data-cell">{spf.fields[11].value}</td>
                                        <td width="8%" className="left-align-data-cell">{new Date(spf.fields[5].value) < new Date() ? "Inactive" : "Active"}</td>
                                        {/* <td width="5%" className="center-align-data-cell"><a href={"http://us-hq-vicwas/Webdocs/SPFWebdocsServlet?action=download&docid=" + spf.id} target="_blank" rel="noopener noreferrer"><img src={download} width='16' height='16' alt="logo"/></a></td> */}
                                        <td width="5%" className="center-align-data-cell"><img className="imgDownload" src={download} width='16' height='16' alt="logo" onClick={this.DownloadSPFDocument} docid={spf.id} docext={spf.fields[6].value} doctitle={spf.title} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row" style={showVersionStyle}>
                    <div className="col-md-12 center-align-data-cell">
                        <div className="col-md-12 col-offset-md-4 left-align-data-cell">
                            <PopupExample/>
                        </div>
                    </div>
                </div>

                <Popup
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                >
                    {close => (
                        <div className="modal">
                            <a href="/#" className="close" onClick={close}>
                                &times;
                            </a>
                            <p className="modalMargin">
                                There are more than {this.state.maxspfs} results for your search query, please filter your results to get a smaller dataset.
                            </p>
                        </div>
                    )}
                </Popup>

                <Popup
                    open={this.state.processoropen}
                    closeOnDocumentClick
                    onClose={this.closeProcessorModal}
                >
                    <div className="modal">
                        Processing your search request...
                    </div>
                </Popup>

                <Popup
                    open={this.state.startdownload}
                    closeOnDocumentClick
                    onClose={this.closeDownloadModal}
                >
                    <div className="modal">
                        Processing your download request...
                    </div>
                </Popup>

                <Popup
                    open={this.state.noresultsopen}
                    closeOnDocumentClick
                    onClose={this.closeNoResultsModal}
                >
                    {close => (
                        <div className="modal">
                            <a href="/#" className="close" onClick={close}>
                                &times;
                            </a>
                            <p className="modalMargin">
                                There are no results for these search parameters.
                            </p>
                        </div>
                    )}
                </Popup>

                <Popup
                    open={this.state.nospfsopen}
                    closeOnDocumentClick
                    onClose={this.closeNoSPFsModal}
                >
                    {close => (
                        <div className="modal">
                            <a href="/#" className="close" onClick={close}>
                                &times;
                            </a>
                            <p className="modalMargin">
                                There are no SPFs for this account.
                            </p>
                        </div>
                    )}
                </Popup>

                <Popup
                    open={this.state.getuserinfoopen}
                    closeOnDocumentClick
                    onClose={this.closeGetUserInfoModal}
                >
                    {close => (
                        <div className="modal">
                            <a href="/#" className="close" onClick={close}>
                                &times;
                            </a>
                            <div className="modalMargin">
                                <div>Test Login Account (UPN):  {this.state.upn}</div>

                                <div>SalesIds: {JSON.stringify(this.state.erps)}</div>
                            </div>
                        </div>
                    )}
                </Popup>

                <Popup
                    open={this.state.noactiveorinactiveopen}
                    closeOnDocumentClick
                    onClose={this.closeNoActiveOrInactiveModal}
                >
                    {close => (
                        <div className="modal">
                            <a href="/#" className="close" onClick={close}>
                                &times;
                            </a>
                            <p className="modalMargin">
                                You cannot deselect Active and Inactive SPFS, please select one.
                            </p>
                        </div>
                    )}
                </Popup>
   
            </div>
        );
    }
}