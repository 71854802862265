import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
// import config from './Config';
// import { getEvents } from './GraphService';

const rowBackgroundColor = ['#f8d7da', '#d4edda'];

function formatDateTime(dateTime) {
  return moment.utc(dateTime).format('M/D/YY h:mm A');
}

function MessagesTable(props) {
  return (
    <Table>
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Email</th>
          <th scope="col">Message</th>
          <th scope="col">User Agent</th>
          <th scope="col">Date</th>
        </tr>
      </thead>
      <tbody>
        {props.messages.map(
          function(message){
            return(
              <tr key={message.id} bgcolor={rowBackgroundColor[message.success]}>
                <td>{message.id}</td>
                <td>{message.email}</td>
                <td>{message.message}</td>
                <td>{message.useragent}</td>
                <td>{formatDateTime(message.createdate)}</td>
              </tr>
            )
        })}
      </tbody>
    </Table>
  );
}

export default class Admin extends React.Component {
  constructor(props) {
    super(props);

    console.log("ISSUPERADMIN: " + props.location.isSuperAdmin);

    this.issuperadmin = this.toggle.bind(this);

    this.issuperadmin = props.location.isSuperAdmin;

    this.state = {
      messages: [],
    };
  }

  async componentDidMount() {
    try {
        this.GetMessages();
    }
    catch(err) {
      this.props.showError('ERROR', JSON.stringify(err));
    }
  }

  async GetMessages() {
    try {
        console.log("GetMessages()");

        const res = await fetch(process.env.REACT_APP_HOST + '/admin/getmessages',
        {
            method: 'POST',
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        
        const json = await res.json();
        
        this.setState({
          messages: json
        });
    } catch (err) {
        // ... error checks
        console.log("Fetch error: " + err);
    }
}

  render() {
    if ((this.issuperadmin) && (this.props.isAuthenticated)) {
        return (
            <div>
              <h1>Error Tracking</h1>
              <MessagesTable
                messages={this.state.messages} />
            </div>
          );
    }
    else {
      // window.location = process.env.REACT_APP_ROOT; 
      return (
            <div></div>
          );
    }    
  }
}