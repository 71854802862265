import React from 'react';
// import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import logo from './img/logo.png';

function UserAvatar(props) {
  // If a user avatar is available, return an img tag with the pic
  if (props.user.avatar) {
    return <img
            src={props.user.avatar} alt="user"
            className="rounded-circle align-self-center mr-2"
            style={{width: '32px'}}></img>;
  }

  // No avatar available, return a default icon
  return <i
          className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
          style={{width: '32px'}}></i>;
}

function AuthNavItem(props) {
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.isAuthenticated) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle nav caret>
          <UserAvatar user={props.user}/>
        </DropdownToggle>
        <DropdownMenu right>
          <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
          <p className="dropdown-item-text text-muted mb-0">{props.user.email}</p>
          <p className="dropdown-item-text text-muted mb-0"><b>UPN:</b>&nbsp;{props.user.upn}</p>
          <DropdownItem divider />
          <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

    );
  }

  // Not authenticated, return a sign in link
  return (
    <NavItem>
      <NavLink onClick={props.authButtonMethod} className="login-button">Sign In</NavLink>
    </NavItem>
  );
}

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.issuperadmin = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

    // console.log("PROPS:" + JSON.stringify(this.props));

    this.superAdminList = [
      /*===== UNCOMMENT BELOW FOR SALESADMIN =====*/
      "pbriggeman@victaulic.com",
      "pbriggeman.adm@victaulic.com",
      "pmarchello@victaulic.com",
      "jmaring@victaulic.com",            
      "it_jm@victaulic.com"
    ];
    
    if (this.props.user != null) {
      this.issuperadmin = this.superAdminList.includes(this.props.user.userName);
    }    
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    // Only show calendar nav item if logged in
    // let calendarLink = null;
    if (this.props.isAuthenticated) {
      // calendarLink = (
      //   <NavItem>
      //     {/* <RouterNavLink to="/calendar" className="nav-link" exact>Calendar</RouterNavLink> */}
      //   </NavItem>
      // );
    }

    //console.log("NAVBAR PROPS ISADMIN : " + this.props.issuperadmin  );

    // if (this.superAdminList.indexOf(this.props.user.email.toLowerCase()) !== -1){
    //   // calendarLink = (
    //   //   <NavItem>
    //   //     {/* <RouterNavLink to="/calendar" className="nav-link" exact>Calendar</RouterNavLink> */}
    //   //   </NavItem>
    //   // );
    // }

    return (
      <div>
        <Navbar color="dark" dark expand="md" fixed="top">
          <Container>
            {/* <NavbarBrand href="/">React Graph Tutorial</NavbarBrand> */}
            <NavbarBrand href="/"><img src={logo} width='110' height='32' alt="logo"/></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto" navbar>
                {/* {calendarLink} */}
              </Nav>
              <Nav className="justify-content-end" navbar>
                {/* <NavItem>
                  <NavLink href="https://developer.microsoft.com/graph/docs/concepts/overview" target="_blank">
                    <i className="fas fa-external-link-alt mr-1"></i>
                    Docs
                  </NavLink>
                </NavItem> */}
                <AuthNavItem
                  isAuthenticated={this.props.isAuthenticated}
                  authButtonMethod={this.props.authButtonMethod}
                  user={this.props.user} />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}