import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { UserAgentApplication } from 'msal';
import NavBar from './NavBar';
import ErrorMessage from './ErrorMessage';
import config from './Config';
import { getUserDetails } from './GraphService';
import 'bootstrap/dist/css/bootstrap.css';
import Calendar from './Calendar';
import Admin from './Admin';

import SearchForm from './SearchForm';

class App extends Component {
  constructor(props) {
    super(props);

    this.userAgentApplication = new UserAgentApplication({
        auth: {
            clientId: config.appId,
            authority: "https://login.microsoftonline.com/d2bfb7fe-352c-4886-98a3-044da283af7e"
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    });

    var user = this.userAgentApplication.getAccount();

    this.state = {
      isAuthenticated: (user !== null),
      user: user,
      error: null,
      isadmin: false,
      issuperadmin: false
    };

    if (user) {
      // Enhance user object with data from Graph
      this.getUserProfile();      
    }
  }

  render() {
    let error = null;
    if (this.state.error) {
      error = <ErrorMessage message={this.state.error.message} debug={this.state.error.debug} />;
      // this.sendMessage("Unknown User", 2);
    }

    return (
      <Router>
        <div>
          <NavBar
            isAuthenticated={this.state.isAuthenticated}
            authButtonMethod={this.state.isAuthenticated ? this.logout.bind(this) : this.login.bind(this)}
            user={this.state.user}
            isadmin={this.state.isadmin}
            issuperadmin={this.state.issuperadmin}/>
            {/* <UserAgent>
              {({ ua }) => {
                return ua.md.ua
              }}
            </UserAgent> */}
          <Container>
            {error}
            <Route exact path="/"
              render={(props) =>
                <SearchForm {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.state.user} />
              } />
            <Route exact path="/calendar"
              render={(props) =>
                <Calendar {...props}
                  showError={this.setErrorMessage.bind(this)} />
              } />
            <Route exact path="/admin"
              render={(props) =>
                <Admin {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  isAdmin={this.state.isadmin}
                  isSuperAdmin={this.state.issuperadmin}  
                  showError={this.setErrorMessage.bind(this)} />
              } />
          </Container>
        </div>
      </Router>
    );
  }

  setErrorMessage(message, debug) {
    this.setState({
      error: {message: message, debug: debug}
    });
  }

  async login() {
    try {
      await this.userAgentApplication.loginPopup(
        {
          scopes: config.scopes,
          prompt: "select_account"
      });
      await this.getUserProfile();
    }
    catch(err) {
      var error = {};

      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        var dbg = "";
        if (err.message === "User cancelled the flow.") {
          dbg = "User has cancelled the Office365 login window before authentication.";
        }
        else if (err.message === "Login_In_Progress: Error during login call - login is already in progress.") {
          dbg = "An Office365 login window is already open, please close the login window and try logging in again.";
        }
        error = {
          message: "LOGIN ERROR",
          debug: dbg         
        };
      }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async getUserProfile() {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      var accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      });

      if (accessToken) {
        // Get the user's profile from Graph
        var user = await getUserDetails(accessToken);
        this.setState({
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            upn: user.userPrincipalName,
            email: user.mail || user.userPrincipalName
          },
          error: null
        }, function () {          
          this.sendMessage(user.mail, 1);
				});        
      }
    }
    catch(err) {
      var error = {};
      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          // debug: JSON.stringify(err)
          debug: err.message
        };
      }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }

  sendMessage(email, messageid) {
    try {
      fetch(process.env.REACT_APP_HOST + '/admin/insertmessage',
      {
          method: 'POST',
          body: JSON.stringify({
              email: email,
              useragent: navigator.userAgent,
              messageid: messageid
          }),
          headers: {
              "Content-type": "application/json; charset=UTF-8"
          }
      });
    } catch (err) {
        // ... error checks
        console.log("Fetch error App.InsertMessage: " + err);
    }
  }
}

export default App;